/* ==========================================================
 * [custom.js]
 * Project:			wip_boilerplate 2.4.0
 * Description:		javascript assets
 * Start on:		27/02/2015
 * Copyright:		2015 Wip Italia S.r.l.
 * Author URI:		http://www.wipitalia.it
 * ========================================================== */

var wipwrp = window.wipwrp || {};

wipwrp.container = (function ($, mzr, doc, win) {
  var pageType = $("body").attr("data-page");

  function _init() {
    wipwrp.commons.init();
    _switchPage();
  }

  function _switchPage() {
    console.log("pageType: " + pageType);
    switch (pageType) {
      case "login":
        wipwrp.login.init();
        break;
      case "reset-password":
        wipwrp.login.init();
        break;
      case "nuovo-utente":
        wipwrp.register.init();
        break;
      case "domanda":
        wipwrp.domanda.init();
        break;
      case "domanda-landing":
        wipwrp.domandalanding.init();
        break;
      case "catalogo":
        wipwrp.catalogo.init();
        break;
      case "home":
      case "dashboard":
        $(".masonry-wrapper").masonry({
          itemSelector: ".grid-item",
          columnWidth: ".grid-sizer",
          percentPosition: true,
        });
        wipwrp.dashboardPopup.init();
        wipwrp.commons.setGraficoAcconto();
        wipwrp.fieraMilanoPortalModal.init();
        break;
      case "profilo":
        wipwrp.profilo.init();
        break;
      case "la-tua-fiera":
        wipwrp.commons.setGraficoAcconto();
        wipwrp.fiera.init();
        wipwrp.fieraMilanoPortalModal.init();
        break;
      case "faq":
        wipwrp.accordion.init();
        break;
      case "rappresentate":
        wipwrp.rappresentate.init();
        break;
      case "contattaci":
        $("form#form_contattaci").parsley();
        break;
      case "aflive":
      case "aflive-package-details":
        wipwrp.afLive.init();
        break;
      case "aflive-presence":
        wipwrp.aflivePresence.init();
        wipwrp.accordion.init();
        wipwrp.sharingCostsModal.init();
        break;
      case "servizi":
        wipwrp.fieraMilanoPortalModal.init();
        break;
    case "pfaf":
        wipwrp.pfaf.init();
        break;
    case "pfaf-mypos":
        wipwrp.pfafMyPos.init();
        break;
    }
  }

  //  return public methods
  return {
    init: function () {
      return _init();
    },
  };
})(jQuery, Modernizr, document, window);
